

.main_purchases {
    padding-top: 25px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 10px;

    &-card {
        padding: 40px;
        border: 1px solid lightgray;
        box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.2);
        // font-size: 14px;
        
        &__content {
            // padding: 15px;
            // height: 500px;
            overflow-x: auto;
            left: 10px !important;
            padding-bottom: 25px;

            &-header {
                min-width: 1800px;
                display: flex;
                margin-top: 30px;
                padding: 15px;
                background-color: #000;
                color: #fff;
                align-items: center;

                &__col {
                    min-width: 150px;
                }
            }
    
            &-purchase {
                min-width: 1800px;

                &__row {
                    display: flex;
                    margin-top: 5px !important;
                    padding: 15px;
                    border-color: lightgray;
                    border-width: 1px;
                    border-style: solid;
                    align-items: center;

                    &-col {
                        min-width: 150px;
                    }
                }
            }
        }

        button {
            padding: 10px;
            background-color: gray;
            border-color: gray;

            &:hover, 
            &:active,
            &:focus {
                background-color: gray;
                border-color: gray;
            }
        }
    }
}
