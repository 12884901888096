
.form-contact {
    padding-top: 30px;
    border-top: 0.5px solid lightgray;

    &__send {
        position: relative;
        // border-top: 0.5px solid lightgray;
        margin-top: 25px;

        label {
            &:first-of-type {
                margin-top: 25px;
            }
        }

        input {
            border: 0 !important;
            width: 100%;
            height: 40px;
            padding: 10px 25px;
            font-size: 16px;
            // color: #fff !important;
            border-radius: 5px;
            border-color: lightgray;
            background-color: lightgray;
            
            margin-bottom: 8px;
    
            &:focus {
                outline: none;
            }
        }

        button {
            margin-top: 25px;
            padding: 10px;
            background-color: orangered;
            border-color: orangered;

            &:hover, 
            &:active,
            &:focus {
                background-color: orangered;
                border-color: orangered;
            }
        }
    }
}