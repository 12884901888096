
.header {
    justify-content: center;

    &-background {
        

        img {
            margin-top: 50px;
            position: absolute;
            width: 100%;
            max-height: 200px;
        }
    }       

    &-container {
        z-index: 1;        
        text-align: center;

        &__info {
            position: relative;
            //background-color: red;
            width: 80%;

            img {
                margin-top: 35px;
                margin-bottom: 20px;
                height: 200px;
                width: 400px;
            }

            p {
                font-size: 18px;
            }
        }  
    }
}

.body {
    text-align: center;
    z-index: 1;
    position: relative;

    &-aura {
        // padding-top: 73px;
        padding-bottom: 20px;
        border-bottom: 0.5px solid lightgray;
        // text-align: center;
    
        img {
            margin-top: 35px;
            margin-bottom: 20px;
            height: 175px;
        }
    
        h1 {
            padding-top: 35px;
            padding-bottom: 5px;
        }
    
        p {
            font-size: 18px;
            // text-align: justify;
        }
    }

    &-plans {
        padding-top: 35px;
        margin-bottom: 20px;
        // border-top: 1px solid lightgray;
        // text-align: center;
    }
}    
