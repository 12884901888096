
.main {
    padding-top: 25px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 10px;

    &-card {
        padding: 40px;
        border: 1px solid lightgray;
        box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.2);
        
        &__error {
            color: red;
            // font-weight: bold;
            font-size: 16px;
            padding-top: 25px;
        }

        &__form {
            position: relative;
            margin-top: 40px;       
    
            label {
                padding-top: 10px;
                padding-bottom: 5px;
            }
    
            input, select {
                border: 0 !important;
                width: 100%;
                height: 40px;
                padding: 10px 25px;
                font-size: 16px;
                // color: #fff !important;
                border-radius: 5px;
                border-color: lightgray;
                background-color: lightgray;
                
                margin-bottom: 8px;
        
                &:focus {
                    outline: none;
                }
            }

            &-validation {
                font-size: 13px;
                
                p {
                    margin: 0;
                }

                &__correct {
                    color: green;
                }

                &__wrong {
                    color: red;
                }
            }
    
            button {
                margin-top: 50px;
                padding: 10px;
                background-color: orangered;
                border-color: orangered;
    
                &:hover, 
                &:active,
                &:focus {
                    background-color: orangered;
                    border-color: orangered;
                }
            }
        }

        &__options {
            padding-top: 25px;
            font-size: 13px;

            a {
                padding-right: 15px;
                color: blue;

                &:hover, 
                &:active,
                &:focus,
                &:visited {
                    color: blue;
                }
            }          
        }
    }
}
