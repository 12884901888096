
.resume {
    padding-top: 20px;
    padding-bottom: 50px;

    h2 {
        padding-top: 20px;
    }

    &-clear {
        width: 100%;
        padding: 10px;

        button {
            margin: 0;
            padding: 0;
            color: #000;
            text-decoration: none;
    
            &:hover {
                text-decoration: none;
                color: red;
    
                svg {
                    fill: red;
                }
            }
    
            svg {
                height: 20px;
                width: 20px;
                fill: #000;
                margin-left: 10px;
            }
        }
    }

    &-header {
        // background-color: red;
        padding-top: 15px;
        border-bottom: 0.5px solid lightgray;
    }

    &-content {
        padding-left: 12px;
        padding-right: 12px;

        &__line {
            padding: 10px;
            align-items: center;
            border-bottom: 0.5px solid lightgray;     
            
            p {
                margin: 0;
            }

            img {
                width: 50px;
                border-radius: 5px;
            }

            &-obj {
                padding: 0 !important;
                display: flex;
                align-items: center;

                p {
                    margin-left: 10px;
                }
            }

            button {
                background-color: #000;
                border-color: #000;
                color: #fff;
                border-radius: 5px;
                margin: 0 5px;
                width: 27px;
                height: 27px;
            }
        }       
    }

    &-total {
        padding-top: 15px;
        
        div {
            display: flex;
            justify-content: space-between;
            
            padding-top: 10px;

            &:nth-child(1) {
                border-bottom: 0.5px solid lightgray;
            }

            &:nth-child(2) {
                border-bottom: 0.5px solid lightgray; 
            }

            h5 {
                padding-left: 10px;
            }

            p {
                margin-right: 10px;
            }
        }

        button {
            margin-top: 15px;
            padding-top: 5px;
            color: #fff;
            background-color: #000;
            width: 100%;
            border: 0;
            font-size: 18px;
            border-radius: 5px;
            padding-bottom: 5px;

            &:hover {
                color: #fff;
                background-color: #000;
            }
        }
    }
}