
.loading {
    width: 100%;
    text-align: center;
    padding-top: 100px;

    h5 {
        font-weight: normal;
        padding-top: 20px;
    }
}