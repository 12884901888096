
a {
    &:link,
    &:visited,
    &:active {
        text-decoration: none !important;
    }    
}

.menu__icon {
    width: 30px;
}