.cart {
    display: flex;
    padding-top: 5px;
    color: #000;

    &-button { 
        svg {
            width: 30px;
            fill: #343a40;        
        }
    }

    &-quantity {
        color: red;
        font-weight: bold;
    }
}


.cart-content {
    overflow: hidden;
    // overflow-y: auto;
    // overflow-x: hidden;
    position: absolute;
    top: 0;
    right: 0;
    background-color: #343a40;
    height: 100vh;
    text-align: left;
    white-space: nowrap;
    transition: width 1s;
    z-index: 9;

    .cart-content__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px;
        border-bottom: 1px solid #4f5256;
        height: 65px;

        > div {
            &:first-of-type {
                display: flex;
                svg {
                    width: 12px;
                    margin-right: 20px;
                    fill: #fff;
                    
                    &:hover {
                        cursor: pointer;
                    }
                }
            
                h2 {
                    color: #fff;
                    margin: 0;
                    font-size: 24px;
                }
            }
        }

        button {
            margin: 0;
            color: #fff;
            text-decoration: none;

            &:hover {
                text-decoration: none;
                color: red;

                svg {
                    fill: red;
                }
            }

            svg {
                height: 20px;
                width: 20px;
                fill: #fff;
                margin-left: 10px;
            }
        }
    }

    .cart-content__products {
        height: calc(100vh - 76px - 119px);
        // overflow: auto;
        overflow-y: auto;
        overflow-x: hidden;
        border-bottom: 1px solid #4f5256;

        &-empty {
            color: #fff;
            justify-content: center;
            font-size: 20px;
            padding: 10px;
        }
    }

    .cart-content__product {
        display: flex;
        align-items: center;
        padding: 10px 15px;
        border-bottom: 1px solid #4f5256;

        img {
            width: 70px;
            margin-right: 10px;
            border-radius: 5px;
        }

        .cart-content__product-info {
            color: #fff !important;
            width: 100%;
            margin-left: 5px;

            h3 {
                font-size: 15px;
                margin-left: 10px;
                color: #fff !important;
            }
            
            p {
                font-size: 13px;
                margin-left: 10px;
                margin-bottom: 5px;
            }

            > div {
                &:first-of-type {
                    border-bottom: 1px solid #4f5256;
                }
            
                &:last-of-type {
                    margin-top: 5px;
                    display: flex;
                    justify-content: space-between;

                    p {
                        margin: 0;
                        margin-left: 10px;
                    }

                    button {
                        background-color: #0098d3;
                        border-color: #0098d3;
                        color: #fff;
                        border-radius: 5px;
                        margin: 0 5px;
                        width: 27px;
                        height: 27px;
                    }
                }
            }
        }
    }

    .cart-content__footer {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        // border-top: 1px solid #4f5256;
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 15px;

        > div {
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #fff;
            padding: 0 20px;
            font-size: 13px;
            
            &:nth-child(1) {
                border-bottom: 1px solid #4f5256;
            }

            &:nth-child(2) {
                border-bottom: 1px solid #4f5256;
            }

            &:last-of-type {
                margin-bottom: 8px;
                font-weight: bold;
            }

            p {
                margin: 0 !important;
            }
        }

        a {
            text-decoration: none;
        }

        button {
            color: #fff;
            background-color: #0098d3;
            width: 100%;
            border: 0;
            font-size: 18px;
            border-radius: 5px;
            padding-bottom: 5px;
            text-decoration: none !important;
        }
    }
}