
.footer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    padding: 15px 0 !important;
    text-align: center;

    a {
        &:link,
        &:visited,
        &:active {
            text-decoration: none !important;
        }    
    }

    &-row {
        border-bottom: 0.5px solid lightgray;
        padding-top: 20px;
        padding-bottom: 20px;

        &_col1{
            img {
                height: 75px;
            }

            &-redesImg {
                img {
                    height: 35px; 
                    margin: 10px; 
                }
            }
        }
    }

    p {
        margin: 10px;
        color: #565656;
    }
}
