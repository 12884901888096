
.floating {
    margin-top: 100px;
    background-color: transparent;
    // overflow: hidden;
    position: absolute;
    bottom: 55px;
    left: 10px;
    z-index: 9;

    .floating-container {     
        position: fixed;

        a {
            &:link,
            &:visited,
            &:active {
                text-decoration: none !important;
            }    
        }
        
        Button {
            display: flex;
            align-items: center;
            border-radius: 25px;           
            color: #000;
            font-weight: bold;
            background-color: #fff;
            border-color: #fff;
            box-shadow: 2px 2px 10px 1px rgba(0, 0, 0, 0.2);

            &:hover, 
            &:active,
            &:focus {
                background-color: #fff;
                border-color: #fff;
                color: #000;
            }

            img {
                height: 30px;
                margin-left: 5px;
                margin-right: 5px;
            }
        }
    }
}