
.MuiToolbar-root {
    background-color: #F5F5F5;

    img {
        width: 110px;
        padding: 0;
    }
}

.top-menu__icon-lg {
    margin-left: 150px;
}

.top-menu__icon-sm {
    margin-top: 7px;
    margin-bottom: 7px;
}

.top-menu__options {
    margin-left: 100px;
    
    a {
        text-decoration: none;
        color: #000;

        Button {
            &:visited,
            &:active,
            &:hover {
                border-color: transparent !important;
                color: orangered !important;
            }
        }
    }
}

.top-menu__icons {
    display: flex;
    margin-left: 100px;
    
    a {
        margin: 10px;
        display: flex;
        text-align: center;

        img {
            width: 30px;
        }
    }    
}