
.modal-button {
    margin: 10px;
    width: 75px;
}

.main_plans {
    padding-top: 25px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 10px;

    &-card {
        padding: 40px;
        border: 1px solid lightgray;
        box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.2);
        // font-size: 14px;
        
        &__content {
            // padding: 15px;
            // height: 500px;
            overflow-x: auto;
            left: 10px !important;
            padding-bottom: 25px;

            &-header-plan {
                min-width: 1600px;
                display: flex;
                margin-top: 30px;
                padding: 15px;
                background-color: #000;
                color: #fff;
                align-items: center;

                &__col {
                    min-width: 200px;
                }
            }
    
            &-plan {
                min-width: 1600px;

                &__row {
                    display: flex;
                    margin-top: 5px !important;
                    padding: 15px;
                    border-color: lightgray;
                    border-width: 1px;
                    border-style: solid;
                    align-items: center;

                    &-col {
                        min-width: 200px;
                    }
                }
            }
        }

        button {
            padding: 8px;
            background-color: orangered;
            border-color: orangered;

            &:hover, 
            &:active,
            &:focus {
                background-color: orangered;
                border-color: orangered;
            }        
        }
    }
}
