
.empresa {
    padding-top: 35px;
    text-align: justify;

    h5 {
        text-align: center;
        margin-bottom: 30px;
        padding: 0;
        background-color: orangered;
        color: #fff;
        border-radius: 5px;
        padding-top: 5px;
        padding-bottom: 5px;
    }

    h1 {
        text-align: center;
        margin-bottom: 30px;
    }

    p {
        font-size: 18px;
    }

    &-header {
        padding-bottom: 25px;
    }

    &-body {
        padding-top: 50px;
        padding-bottom: 50px;
        border-top: 0.5px solid lightgray;
        // border-bottom: 0.5px solid lightgray;

        .info-row {
            padding-top: 10px;
            padding-bottom: 10px;
        }

        .row {
            align-items: center;
        }

        h4 {
            text-align: start;
        }

        p {
            font-size: 15px;
            text-align: justify;
        }
        
        img {
            width: 100%;
            border-radius: 15px;
            box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.2);
        }
    }

    &-footer {
        padding-top: 30px;
        margin-bottom: 50px;
    }
}