
.form-payment {
    padding-top: 30px;
    // border-top: 0.5px solid lightgray;

    .messagePayment {
        color: red;
    }
}

/****************************** CSS for credit card ************************/
.card-ui-component-field {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.card-ui-component-ddl-expiry {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    float: left;
    margin-right: 10px;

    &:first-of-type {
        margin-top: 10px;
    }
}

.card-ui-component-card-bg {
    padding: 6px 0px;
    float: right;
    width: auto;
    position: absolute;
    top: -5px;
    right: 0;
}

@media only screen and (max-width : 786px) {
    .card-ui-component-card-bg {
        padding: 6px 0px;
        float: left;
        width: auto;
        position: static;
    }
}

ul.card-ui-component-cctypes li {

    background-size: 200% 600%;
    background-position: 0 0;
    float: right;
    height: 29px;
    margin-right: 5px;
    text-indent: 9999px;
    transition: all 0.2s ease 0s;
    width: 40px;
}

ul.card-ui-component-cctypes {
    list-style: none outside none;
    margin: 0;
    overflow: hidden;
    padding: 0;
    margin-top: 2px;
    margin-right: 3px;
}

/*.card-ui-component-cctypes .card-ui-component-visa {
    background-position:  120px -29px;
}*/
.card-ui-component-cctypes .card-ui-component-visa.on {
    background-image: url("http://bacapicomponent.test.merchantprocess.net/Content/Images/ccTypesSprite.png?v=1");
    background-position: -120px 0px;
    right: 0px;
    position: absolute;
}

/*.card-ui-component-cctypes .card-ui-component-mastercard {
    background-position: 0 29px;
}*/
.card-ui-component-cctypes .card-ui-component-mastercard.on {
    background-image: url("http://bacapicomponent.test.merchantprocess.net/Content/Images/ccTypesSprite.png?v=1");
    right: 0px;
    position: absolute;
    background-position: 0 0;
}

.card-ui-component-cctypes .card-ui-component-diners_club_carte_blanche.on {
    background-image: url("http://bacapicomponent.test.merchantprocess.net/Content/Images/ccTypesSprite.png?v=1");
    right: 0px;
    position: absolute;
    background-position: -1px -58px;
}

.card-ui-component-cctypes .card-ui-component-amex.on {
    background-image: url("http://bacapicomponent.test.merchantprocess.net/Content/Images/ccTypesSprite.png?v=1");
    right: 0px;
    position: absolute;
    background-position: -1px -29px;
}

.card-ui-component-cctypes .card-ui-component-discover.on {
    background-image: url("http://bacapicomponent.test.merchantprocess.net/Content/Images/ccTypesSprite.png?v=1");
    right: 0px;
    position: absolute;
    background-position: -120px -29px;
}

.card-ui-component-cctypes .card-ui-component-jcb.on {
    background-image: url("http://bacapicomponent.test.merchantprocess.net/Content/Images/ccTypesSprite.png?v=1");
    right: 0px;
    position: absolute;
    background-position: -120px -58px;
}

.card-ui-component-heading {
    color: #fff;
    background-color: #337ab7;
    border-color: #337ab7;
    padding: 10px 15px;
    border-bottom: 1px solid transparent;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.card-ui-component-outer {
    // margin-top: 20px;
    background-color: #fff;
    border: 1px solid #337ab7;
    border-radius: 4px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
    width: 100%;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 100px;
}

.card-ui-component-inner-body {
    padding: 15px;
}

.card-ui-component-label-style {
    width: 100%;
    display: left;
    padding-top: 7px;
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: 700;
}

.card-ui-component-input-style {
    width: 100%;
    // float: right;
    position: relative;
}

.card-ui-component-input-style focus {
    border: 1px solid #2e6da4;
}

.card-ui-component-validation-error {
    border-width: 2px;
    border-color: red;
    // border: 1px;
}

.card-ui-component-row-static {
    width: 100%;
    margin-bottom: 5px;
    display: inline-block;
}

.card-ui-component-save-btn {
    color: #fff;
    background-color: #337ab7;
    border-color: #2e6da4;
}

.card-ui-component-save-btn:hover {
    color: #fff;
    background-color: #204d74;
    border-color: #122b40;
}

.card-ui-component-cancel-btn {
    color: #333;
    background-color: #d4d4d4;
    border-color: #8c8c8c;
}

.card-ui-component-cancel-btn:hover {
    color: #333;
    background-color: #e6e6e6;
    border-color: #adadad;
}

.card-ui-component-btn-action {
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
}

.card-ui-component-action-container {
    text-align: center;
}

.border-line {
    margin-top: 20px;
    margin-bottom: 20px;
    border: 0;
    border-top: 1px solid #eee;
}

.card-ui-component-select-input {
    width: 10%;
}

.card-ui-component-alert {
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 4px;
}

.card-ui-component-danger-alert {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
    border-radius: 0;
}

.card-ui-component-success-alert {
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #d6e9c6;
}