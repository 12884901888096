
.contacto-background {
    width: 100%;

    img {
        width: 100%;
    }
}

.contacto {
    margin-top: 50px;
    margin-bottom: 50px;

    h1 {
        margin-bottom: 30px;
    }

    &-redesImg {
        img {
            height: 65px; 
            margin: 10px; 
        }

        margin-bottom: 10px;
    }
}