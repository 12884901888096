
.AuraMain {
    margin-top: 35px;
    text-align: justify;
    border-top: 0.5px solid lightgray;

    h2 {
        margin-bottom: 25px;
        // color: #1E3D8D;
    }

    p {
        color: #878789;
    }

    .colImg {
        text-align: center;
    }

    .colTxt {
        padding-left: 25px;
        padding-right: 25px;
    }

    &-Row1 {
        align-items: center;
        font-size: 18px;

        img {
            height: 350px;
            margin-top: 25px;
            margin-bottom: 25px;
            border-radius: 5px;
            box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.2);
        }

        Button {
            margin-top: 20px;
            height: 50px;
            background-color: orangered;
            border-color: orangered;

            &:hover, 
            &:active,
            &:focus {
                background-color: orangered;
                border-color: orangered;
            }
        }
    }

    &-RowProveedores {
        padding-top: 30px;
        padding-bottom: 30px;
        text-align: center;
        // background-color: lightgray;

        img {
            margin-top: 30px;
            margin-bottom: 30px;
            height: 50px;
        }
    }

    &-Row2 {
        padding-top: 5px;
        align-items: center;
        font-size: 15px;

        img {
            max-height: 400px;
            margin-top: 25px;
            margin-bottom: 25px;
            // border-radius: 15px;
            // box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.2);
        }
    }
}