
.successful {
    width: 100%;
    text-align: center;
    padding-top: 100px;

    img {
        width: 300px;
    }

    h4 {
        font-weight: bold;
        padding-top: 20px;
    }

    h5 {
        font-weight: normal;
        padding-top: 20px;
    }
    
    button {
        margin-top: 30px;
    }
}