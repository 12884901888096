
.slider-main {
    height: 500px;

    &__image {
        height: 500px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center top;

        &-info {
            display: flex;
            align-items: flex-end;
            height: 100%;
            padding-bottom: 40px;

            > div {
                // background-color: #a7a7a7aa;
                margin:  0 auto;
                text-align: center;
                width: 60%;
                padding: 20px;
            }
        }
    }
}