
.background-plan {
    background-color: #F5F5F7;

    .product-plan {
        background-color: #fff;
        padding: 55px;

        h1 {
            margin-bottom: 30px;
        }

        p {
            margin: 0;
            font-size: 18px;
        }

        &__header {
            padding-bottom: 30px;
            margin-bottom: 30px;
            border-bottom: 0.5px solid lightgray;

            &-image {
                text-align: end;

                img {
                    max-height: 400px;
                    // width: 100%;
                    border-radius: 15px;
                    box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.2);
                }
            }

            &-info {
                padding: 25px;
        
                h3 {
                    margin-bottom: 20px;
                }
        
                div {
                    margin-bottom: 30px;
                }
        
                .btn {
                    background-color: transparent;
                    color: #0098d3;
                    border-color: #0098d3;
                    margin-bottom: 10px;
                    margin-right: 20px;
        
                    &:hover, 
                    &:active,
                    &:focus {
                        background-color: #0098d3 !important;
                        border-color: #0098d3;
                        color: #fff;
                        box-shadow: none !important;
                    }
                }
            }
        }

        &__body {
            padding: 15px;

            &-list {
                align-items: center;
            }
    
            &-list__header {
                margin-bottom: 15px;
                align-items: center;
            }
    
            &-list__item {
                p {
                    padding: 0;
                    padding-top: 5PX;
                    margin-bottom: 5px;
                    font-size: 15px;
                }
    
                &-row {
                    align-items: center;
                    border-bottom: 0.5px solid lightgray;
    
                    &:last-child {
                        border-bottom: 0;
                    }

                    .category {
                        font-size: 13px;
                        color: gray;
                    }
                }
            } 
        }     
    }
}