
.product {
    padding: 0;
    font-family: Arial, Helvetica, sans-serif;

    .card {
        margin: 15px;
        border-radius: 25px;
        box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.2);

        .card-img-top {
            max-height: 300px;
            border-top-right-radius: 25px;
            border-top-left-radius: 25px;
            // margin-top: 1px;
            // margin-left: 1px;
            // margin-right: 2px;
        }

        .card-title {
            margin: 0;
            margin-bottom: 5px;
            margin-left: 10px;
            font-size: 35px;
        }

        .card-text {
            margin: 0;
            margin-bottom: 5px;
            margin-left: 10px;

            &:first-of-type {
                color: gray;
                font-size: 20px;
            }

            &:last-of-type {
                margin-bottom: 25px;
                font-size: 13px;
                color: gray;
            }    
        }

        .product-resume {
            .card-text {
                font-size: 15px;
                color: #000;
                text-align: justify;

                &:last-of-type {
                    margin-bottom: 15px;
                }
            }             
        }
        
        a {
            .btn {
                width: 100%;
                background-color: transparent;
                color: #000;
                border-color: transparent;
                margin-bottom: 10px;
                font-size: 13px;
                color: gray;
                text-align: start;

                &:hover, 
                &:active,
                &:focus {
                    background-color: transparent !important;
                    border-color: transparent;
                    color: gray;
                    box-shadow: none !important;
                }
            }
        }

        .btn {
            width: 100%;
            background-color: transparent;
            color: #0098d3;
            border-color: #0098d3;
            margin-bottom: 10px;

            &:hover, 
            &:active,
            &:focus {
                background-color: #0098d3 !important;
                border-color: #0098d3;
                color: #fff;
                box-shadow: none !important;
            }
        }
    }
}